import React from "react"
import { Link, graphql } from "gatsby"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function Straw({ data })  {

  const content1 = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(data.markdownRemark.frontmatter.section1.content)
    .toString()

  const content2 = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(data.markdownRemark.frontmatter.section2.content)
    .toString()

  const content3 = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(data.markdownRemark.frontmatter.section3.content)
    .toString()

  return (
    <Layout pageInfo={{ pageName: "Straw" }}>
      <SEO title="Straw" />
      <div>
        <div className="fw banner straw-banner">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>{data.markdownRemark.frontmatter.title}</h1>
                <Link to="for-sale" className="btn btn-blue">
                  Buy Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="fw highlight-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-6 large-p">
                <div className="fw block-50-50">
                  <h2>{data.markdownRemark.frontmatter.section1.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content1,
                    }}
                  />
                  <Link to="for-sale" className="btn btn-blue">
                    Shop Pellets
                  </Link>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 hands-img" />
            </div>
          </div>
        </div>
        <div className="fw warm-grey-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-6 bg-img-3" />
              <div className="col-sm-12 col-md-6 large-p">
                <div className="fw block-50-50">
                  <h2>{data.markdownRemark.frontmatter.section2.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content2,
                    }}
                  />
                  <Link to="for-sale" className="btn btn-blue">
                    Shop Pellets
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fw blue-bg sect-pad-top sect-pad-bot watermark">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-12 col-sm-11 testimonial">
                <h3>{data.markdownRemark.frontmatter.testimonial.quote}"</h3>
                <p>
                  <strong>
                    {data.markdownRemark.frontmatter.testimonial.name}
                  </strong>
                  , {data.markdownRemark.frontmatter.testimonial.ptitle}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fw highlight-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-6 bg-img-4" />
              <div className="col-sm-12 col-md-6 large-p">
                <div className="fw block-50-50">
                  <h2>{data.markdownRemark.frontmatter.section3.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content3,
                    }}
                  />
                  <Link to="for-sale" className="btn btn-blue">
                    Shop Pellets
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/markdowns/straw.md/" }) {
      frontmatter {
        title
        section1 {
          title
          content
        }
        section2 {
          title
          content
        }
        testimonial {
          quote
          name
          ptitle
        }
        section3 {
          title
          content
        }
      }
    }
  }
`
